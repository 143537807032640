import * as Yup from 'yup';
import * as validation from '../../../../utils/validation';
import * as newQuoteValidation from '../../../validation/newQuoteValidation';
import { isEmptyNumberOrString, maxLoanAmount } from '../../../validation/rateValidation';

export const errorMessageToYupException = (errorMessage, { path, createError }) => {
  if (!errorMessage) {
    return true;
  } else {
    return createError({ path, message: errorMessage });
  }
};

export const stringErrorTest = testFn => (value, ctx) =>
  errorMessageToYupException(testFn(value, ctx), ctx);

export const textTrimRequired = Yup.string()
  .trim('Must not include leading/trailing spaces')
  .strict(true)
  .required('Required');

export const moneyTypeErrorMsg = 'Please enter number, ####.## or ####';
export const monthsTypeErrorMsg = 'Please enter a whole number';

export const money = function (value) {
  return errorMessageToYupException(validation.money(value), this);
};

export const minFinancedAmountLessCostOfGap = function (
  _value,
  { parent: { vehicleCondition, msrp, nadaAverageRetailValue } },
) {
  return errorMessageToYupException(
    newQuoteValidation.minFinancedAmountLessCostOfGap({
      vehicleCondition,
      msrp,
      nadaAverageRetailValue,
    }),
    this,
  );
};

export const notBeforeDays = (days = 30) =>
  function (value) {
    return errorMessageToYupException(validation.notBeforeDays(days)(value), this);
  };

export const notInFuture = function (value) {
  return errorMessageToYupException(validation.notInFuture(value), this);
};

export const isZipCode = function (value) {
  return errorMessageToYupException(validation.isZipCode(value), this);
};

export const integerPhoneNumber = function (value) {
  return errorMessageToYupException(validation.integerPhoneNumber(value), this);
};

export const isOnlyAlphabetWithSymbols = function (value) {
  return errorMessageToYupException(validation.isOnlyAlphabetWithSymbols(value), this);
};

export const isPersonName = function (value) {
  if (!value) return true;
  if (value.match(/^[A-Za-z][A-Za-z \-\d',.]*$/)) return true;
  return errorMessageToYupException(
    "Person name should contain only letters, digits, and these symbols: - ' , .",
    this,
  );
};

export const isEmail = function (value) {
  return errorMessageToYupException(validation.email(value), this);
};

export const minLoanAmountFlat = (value, { dealerState, gapContractYear, kelleyBlueBook }) => {
  if (!isEmptyNumberOrString(value)) {
    const parsedValue = parseFloat(value);
    if (
      dealerState === 'CA' &&
      gapContractYear === '2023A' &&
      parsedValue <= parseFloat(kelleyBlueBook) * 0.7
    ) {
      return 'Loan Amount of the GAP must be more than 70% of the Kelley Blue Book Value.';
    }
  }
};

export const maxLoanAmountYup = ({ maximumLoanAllowed, dealerState, contractYear }) =>
  function (value, context) {
    return errorMessageToYupException(
      maxLoanAmount({ maximumLoanAllowed, dealerState, contractYear })(value, {
        gapRate: context.parent,
      }),
      this,
    );
  };

export const maxKelleyBlueBookFlat = (value, { dealerState, gapContractYear, loanAmount }) => {
  if (!isEmptyNumberOrString(value)) {
    const parsedValue = parseFloat(value);
    if (
      dealerState === 'CA' &&
      gapContractYear === '2023A' &&
      parsedValue > (1 / 0.7) * parseFloat(loanAmount)
    ) {
      return `The loan amount must be more than 70% of Kelley Blue Book Value. This value is too high for the current loan amount.`;
    }
  }
};

function capRetailGapForOregonDealers(lender) {
  if (lender === 'reliable credit' || lender.includes('cu') || lender.includes('credit union')) {
    return false;
  }

  return true;
}

function maxRetailGapHandler({ dealerState, lender }) {
  return (value, { loanAmountOfGap }) => {
    if (dealerState === 'OR') {
      if (
        capRetailGapForOregonDealers(lender.toLowerCase()) &&
        20 * parseFloat(value) > parseFloat(loanAmountOfGap)
      ) {
        return 'State law in Oregon requires a retail price less than or equal to 5% of the loan amount.';
      }
    } else if (dealerState === 'CA' && 25 * parseFloat(value) > parseFloat(loanAmountOfGap)) {
      return 'State law in California requires a retail price less than or equal to 4% of the loan amount.';
    } else if (parseFloat(value) >= parseFloat(loanAmountOfGap)) {
      return 'The retail price must be less than the loan amount.';
    }
  };
}

export const maxRetailGap = ({ dealerState, lender }) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      maxRetailGapHandler({ dealerState, lender })(value, parent),
      this,
    );
  };

const toLowerString = value => (_.isString(value) ? _.toLower(value) : value);

export const matchCI = (path, errorMessage) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      toLowerString(_.get(parent, path)) !== toLowerString(value) ? errorMessage : undefined,
      this,
    );
  };

export const notMatchCI = (path, errorMessage) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      toLowerString(_.get(parent, path)) === toLowerString(value) ? errorMessage : undefined,
      this,
    );
  };

export const ifCondition = (condition, test) =>
  function (...args) {
    if (!condition(...args)) return true;
    return test.call(this, ...args);
  };

export const requiredIfTrue = (cb, args = {}) =>
  function (value, { parent }) {
    return errorMessageToYupException(
      cb({ ...args, ...parent }) && validation.required(value),
      this,
    );
  };
